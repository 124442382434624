import Vue from 'vue'
import App from './LocaleProvider.vue'
import router from './router/router'
import Router from 'vue-router'
import VueClipboard from 'vue-clipboard2'
import storeLocal from 'storejs'
import apiObj from "@a/js/api.js"
import VueLazyload from 'vue-lazyload'
import AOS from 'aos'


// 样式重置
import '@/assets/styles/reset.css'
import '@/assets/styles/public.css'

// 引入AOS样式
import 'aos/dist/aos.css'

Vue.config.devtools = apiObj.VUE_APP_NAME !== 'production'

// 引入ant组件
import {
  LocaleProvider,
} from 'ant-design-vue'

Vue.use(LocaleProvider)

Vue.use(VueClipboard)
// Vue.use(AOS)
AOS.init(
  {
    easing: 'ease',
    duration: 1000
  }
);

const loadimage = require('./assets/img/public/loading.gif')
const errorimage = require('./assets/img/public/error.png')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 2,
  lazyComponent: true
})

Vue.config.productionTip = false
import { message } from 'ant-design-vue'
Vue.prototype.$message = message
Vue.prototype.$storeLocal = storeLocal
Vue.prototype.$AppName = 'official'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
new Vue({
  router,
  data: () => {
    return {
      isVersion: 0,
    }
  },
  render: (h) => h(App),
}).$mount('#app')
