const HOST = window.location.host;
const ProArr = ['www.banhuiyun.com', 'www.banhuijia.com', 'banhuiyun.com', 'banhuijia.com']
const IsPro = ProArr.indexOf(HOST) != -1 ? true : false
const apiObj = {
  VUE_APP_NAME: IsPro ? "production" : "development",
  VUE_APP_BASE_API:
    IsPro
      ? "https://api.banhuiyun.com"
      : "https://api.bhykf.com",
  VUE_APP_UPYUN_API:
    IsPro
      ? "https://v0.api.upyun.com"
      : "http://v0.api.upyun.com",
  VUE_APP_BASE_SERVE:
    IsPro
      ? "https://b.banhuiyun.com/"
      : "http://b.bhykf.com/",
  VUE_APP_ABASE_SERVE:
    IsPro
      ? "https://a.banhuiyun.com/"
      : "http://a.bhykf.com/",
  VUE_APP_BASE_CSERVE:
    IsPro
      ? "https://c.banhuiyun.com"
      : "http://c.bhykf.com",
  VUE_APP_BASE_ASSETS:
    IsPro
      ? "https://assets.banhuiyun.com"
      : "http://assets.bhykf.com",
  VUE_APP_BASE_CDN:
    IsPro
      ? "https://cdn.banhuiyun.com"
      : "http://cdn.bhykf.com"
};

module.exports = apiObj;
