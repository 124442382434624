<!-- antd汉化包裹 -->
<template>
  <a-locale-provider :locale="zh_CN">
    <App />
  </a-locale-provider>
</template>

<script>
import App from './App'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  data () {
    return {
      name: 'LocaleProvider',
      zh_CN
    }
  },

  components: {
    App
  }
}

</script>

