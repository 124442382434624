<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {
    // 判断终端类型
    JudgeTerminalType() {
      return new Promise((resolve, reject) => {
        if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
          resolve()
        } else {
          reject()
        }
      })
    }
  },
  mounted() {
    this.JudgeTerminalType().then(() => {
      // console.log('phone')
      this.$router.push({
        name: 'MobileGuide'
      })
    }).catch(() => {
      // console.log('web')
    })
  }
}
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, PingFang SC, Noto Sans, Noto Sans CJK SC, Microsoft YaHei,
    "\5FAE\8F6F\96C5\9ED1", sans-serif;
}
</style>
