import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(Router)

// const DEBUG = process.env.NODE_ENV !== 'production'
// let module = 'official'
// let modulePath = DEBUG ? '/' : `/`

const router = new Router({
  mode: 'history',
  base: './',
  routes: [
    {
      path: '*',
      redirect: '/',
    },
    {
      // 首页
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/home'),
    },
    {
      // 应用
      path: '/app',
      name: 'Applications',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/apps'),
      redirect: '/apps',
      children: [
        {
          path: '/apps',
          name: 'AppsHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/apps/home'),
        }
      ]
    },
    {
      // 产品
      path: '/product',
      name: 'Products',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/products'),
      redirect: '/products',
      children: [
        {
          path: '/products',
          name: 'ProductsHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/products/home'),
        },
        {
          path: '/products/detail',
          name: 'ProductsIntro',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/products/detail'),
        },

      ]
    },
    {
      // 案例
      path: '/case',
      name: 'Cases',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/cases'),
      redirect: '/cases',
      children: [
        {
          path: '/cases',
          name: 'CasesHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/cases/home'),
        },
        {
          path: '/cases/detail/:caseid',
          name: 'CasesIntro',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/cases/detail'),
        },

      ]
    },
    {
      // 方案
      path: '/plan',
      name: 'Plans',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/plans'),
      redirect: '/plans',
      children: [
        {
          path: '/plans',
          name: 'PlansHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/plans/home'),
        },

      ]
    },
    {
      // 价格
      path: '/price',
      name: 'Prices',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/prices'),
      redirect: '/prices',
      children: [
        {
          path: '/prices',
          name: 'PricesHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/prices/home'),
        },

      ]
    },
    {
      // 素材
      path: '/material',
      name: 'Material',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/material'),
      redirect: '/materials',
      children: [
        {
          path: '/materials',
          name: 'MaterialHome',
          component: () => import(/* webpackChunkName: "Official" */ '@/views/material/home'),
        },

      ]
    },
    {
      // 移动端导航页
      path: '/guide',
      name: 'MobileGuide',
      component: () => import(/* webpackChunkName: "Official" */ '@/views/guide'),
    },
    {
      // 404
      path: '/404',
      name: '404',
      component: () =>
        import(/* webpackChunkName: "Official" */ '@/views/404/404.vue'),
      // redirect: '/introduce/options',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path !== from.path) {
    NProgress.start()
  }

  // 权限判断
  // const record = findLast(to.matched, record => record.meta.authority);
  // if (record && !check(record.meta.authority)) {
  //   if (!isLogin() && to.path !== "/user/login") {
  //     next({
  //       path: "/user/login"
  //     });
  //   } else if (to.path !== "/403") {
  //     notification.error({
  //       message: "403",
  //       description: "你没有权限访问，请联系管理员咨询。"
  //     });
  //     next({
  //       path: "/403"
  //     });
  //   }
  //   NProgress.done();
  // }

  next()
})

router.afterEach(() => {
  NProgress.done()
  window.scrollTo(0, 0)
})

export default router
